import React from 'react'

const Footer = () => {
  const style = {
    fontSize: 14,
    color: "#888",
    margin: 20,
    marginTop: 100
  }

  const aStyle = {
    color: "#ccc",
    textDecoration: "none"
  }
  return (
    <footer style={style}>
      <p style={{ margin: 0 }}>This website was made by <a style={aStyle} target="__blank" href="https://jpmcglone.com">JP McGlone</a> and <a style={aStyle} href="https://github.com/johnlappasjr" target="__blank">John Lappas</a></p>
      <p style={{ margin: 0, marginTop: 10, color: "#444" }}>JP McGlone, LLC © 2020</p>
    </footer>
  )
}

export default Footer