import React from "react"
import peterson from "./images/peterson.png"
import data from "./resources/data.json"
import Footer from "./components/footer"
import { Helmet } from "react-helmet"
import "./App.css"

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Jordan Peterson Study Group</title>
      </Helmet>
      <header className="App-header">
        <h1>{data.title}</h1>
        <img src={peterson} alt="logo" />
        <p>{data.description}</p>
        <a
          className="App-link"
          href="https://www.meetup.com/Jordan-Peterson-Study-Group/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to the Meetup
        </a>

        <Footer />
      </header>
    </div>
  );
}

export default App;
